export default function TechStack() {
  const stack = [
    {
      src: "/aws.png",
      alt: "AWS",
    },
    {
      src: "/node.png",
      alt: "NodeJS",
    },
    {
      src: "/react.png",
      alt: "React",
    },
    {
      src: "/postgres.png",
      alt: "PostgreSQL",
    },
    {
      src: "/redis.png",
      alt: "Redis",
    },
  ];
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <p className="text-center text-md font-semibold uppercase text-gray-500 tracking-wide">
          Some of our favorite tools
        </p>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-3 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:gap-x-10  md:max-w-2xl md:gap-x-10 lg:mx-0 lg:max-w-none">
          {stack.map((item) => (
            <div
              key={item.alt}
              className="col-span-1 flex justify-center md:col-span-1 lg:col-span-1"
            >
              <img className="h-12 md:h-20" src={item.src} alt={item.alt} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
