const industries = [
  {
    id: 1,
    name: "Finance",
    description: "Get insights from data, manage risks, and stay safe with powerful banking solutions.",
    imageUrl: "/finance.png",
  },
  {
    id: 2,
    name: "Retail",
    description: "We design & develop eCommerce webshop solutions.",
    imageUrl: "/retail.png",
  },
  {
    id: 3,

    name: "Logistics",
    description: "Improve logistics chains and automate warehouses using tech to reduce costs.",
    imageUrl: "/logistics.png",
  },
  {
    id: 4,
    name: "Education",
    description: "We develop application to assist in personalized learning, online assessments, and certifications.",
    imageUrl: "education.png",
  },
  {
    id: 5,
    name: "Healthcare",
    description: "Empower healthcare business with EHR or mHealth to simplify management.",
    imageUrl: "/healthcare.png",
  },
  {
    id: 6,
    name: "Real estate",
    description: "Get a software solution with integrated MLS data and robust analytics algorithms.",
    imageUrl: "/realEstate.png",
  },
];

export default function Cards() {
  return (
    <div className="max-w-7xl mx-auto sm:px-6 px-8 py-16 sm:py-24 lg:py-24">
      <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {industries.map((industry) => (
          <li key={industry.id} className="col-span-1 flex flex-col text-center bg-gray-50 rounded-lg shadow divide-y divide-gray-200">
            <div className="flex-1 flex flex-col p-8">
              <img className="w-32 h-32 flex-shrink-0 mx-auto " src={industry.imageUrl} alt="" />
              <h3 className="text-lg mt-6 text-gray-900 font-medium">{industry.name}</h3>
              <dl className="text-md mt-1 flex-grow flex flex-col justify-between">
                <dt className="sr-only">Description</dt>
                <dd className="text-gray-500 text-sm">{industry.description}</dd>
              </dl>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
