/* This example requires Tailwind CSS v2.0+ */
const people = [
  {
    name: "Rubin Taipi",
    role: "Co-Founder / CEO",
    description: "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
    imageUrl: "/rubin-linkedin.jpeg",
  },
  {
    name: "Gojart Rusi",
    role: "Head of Operations",
    description: "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
    imageUrl: "/gojart.jpeg",
  },
  {
    name: "Arjete Bytyqi",
    role: "Head of Business Development",
    description: "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
    imageUrl: "/arjete-linkedin.jpeg",
  },
  {
    name: "Valdrin Kraja",
    role: "Project Manager",
    description: "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
    imageUrl: "/valdrin-linkedin.jpeg",
  },
  // More people...
];

export default function Team() {
  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Meet our leadership</h2>
            <p className="text-xl text-gray-500">
              Each member of our team brings deep insights and unparalleled market expertise required to continiously innovate for our clients.
            </p>
          </div>
          <div className="lg:col-span-2">
            <ul role="list" className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
              {people.map((person) => (
                <li key={person.name}>
                  <div className="flex items-center space-x-4 lg:space-x-6">
                    <img className="w-16 h-16 rounded-full lg:w-20 lg:h-20" src={person.imageUrl} alt="" />
                    <div className="font-medium text-lg leading-6 space-y-1">
                      <h3>{person.name}</h3>
                      <p className="text-indigo-600">{person.role}</p>
                      {/* <p className="text-gray-500 text-base">{person.description}</p> */}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
