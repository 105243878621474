import { Link } from "react-router-dom";
import NavRoutes from "./nav_routes";

export default function Footer() {
  return (
    <footer className="bg-gray-900">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {NavRoutes.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <Link to={item.path} className="text-base text-gray-300 hover:text-white">
                {item.name}
              </Link>
            </div>
          ))}
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          {NavRoutes.social.map((item) => (
            <a key={item.name} href={item.href} target="_blank" className="text-gray-400 hover:text-gray-300">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <p className="mt-8 text-center text-base text-gray-400">&copy; {new Date().getFullYear()} Rozafa Consulting LLC All rights reserved.</p>
      </div>
    </footer>
  );
}
