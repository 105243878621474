import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/scroll_to_top";
import Layout from "./layout";
import Home from "./pages/home/home";
import Services from "./pages/services/services";
import Industries from "./pages/industries/industries";
import Contact from "./pages/contact/contact";
import Company from "./pages/company/company";
import Portfolio from "./pages/portfolio/portfolio";
import NotFound from "./not_found";
import ProjectDetails from "./pages/portfolio/components/projectDetails";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="industries" element={<Industries />} />
            <Route path="services" element={<Services />} />
            <Route path="company" element={<Company />} />
            {/* <Route path="portfolio" element={<Portfolio />} /> */}
            {/* <Route path="portfolio/:projectId" element={<ProjectDetails />} /> */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
